import {ISideDrawerComponentProps} from "../../../lib/types/components";
import {publishWithUsType, sendPublishWithUsEvent} from "../../../lib/utils/constants/AnalyticsConstants";
import {GDC_WEB_URL} from "../../../lib/utils/constants/GlobalConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import styles from "./SideDrawerComponent.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const SideDrawerComponent = (props: ISideDrawerComponentProps) => {
    const {handleHamburgerClick, menuOpen, navigationDataLinks} = props;

    const navData = navigationDataLinks;

    return (
        navData ?
            <>
                <aside
                    className={styles.drawer}
                    style={menuOpen ? {width: "100%"} : {width: 0}}
                    onClick={() => handleHamburgerClick()}
                >
                    <div>
                        <nav className={styles.navbar}
                             style={menuOpen ? {transition: 'opacity 0.5s ease-in', opacity: 0.99} : {opacity: 0}}>
                            <div className={multipleClassName("container-fluid", styles.menuContainer)}>
                                {navData.map((data, index) => {
                                    return (
                                        <div key={"gameRow" + index}>
                                            <div className={multipleClassName("row", styles.gameRow)}>
                                                <div className={multipleClassName("col", styles.imgCol)}>
                                                    <div className={styles.imgContainer1}>
                                                        <CustomImageComponent
                                                            layout={"fill"}
                                                            objectFit={"contain"}
                                                            src={data.img}
                                                            alt={"game icon image"}
                                                        />
                                                    </div>
                                                </div>

                                                {data.url ?
                                                    <div onClick={() => {
                                                        data.url === GDC_WEB_URL && sendPublishWithUsEvent(publishWithUsType.MOBILE)
                                                    }}
                                                         className={multipleClassName("col", styles.headingLinkContainer)}>
                                                        <CustomLinkComponent
                                                            className={styles.navItemClickable}
                                                            href={data.url}
                                                            mobStyle={{
                                                                textDecoration: "none"
                                                            }}
                                                            desktopStyle={{
                                                                textDecoration: "none"
                                                            }}
                                                        >
                                                            {data.heading}
                                                        </CustomLinkComponent>
                                                    </div>
                                                    :
                                                    <div className={multipleClassName("col", styles.navItem)}
                                                         key={"navItem" + index}>
                                                        {data.heading}
                                                    </div>
                                                }

                                            </div>
                                            {data.navLinks.length > 0 &&
                                                <div className={styles.subLinksContainer}>
                                                    {data.navLinks.map((item, i) => {
                                                        return (
                                                            item.outside ?
                                                                <a href={item.link} className={styles.clickOutside}
                                                                   key={"clickOutside_" + i}>
                                                                    {item.title}
                                                                </a>
                                                                :
                                                                <span key={"clickOutside_" + i} className={styles.clickInside}>
                                                                    <CustomLinkComponent href={item.link} key={i}
                                                                                         mobStyle={{
                                                                                             color: "#d8d8d8"
                                                                                         }}
                                                                                         desktopStyle={{
                                                                                             color: "#d8d8d8"
                                                                                         }}>
                                                                        {item.title}
                                                                    </CustomLinkComponent>
                                                                </span>
                                                        )
                                                    })
                                                    }
                                                </div>}
                                        </div>

                                    )
                                })

                                }


                            </div>
                        </nav>
                    </div>
                </aside>
            </>
            : <></>
    )
}

export default SideDrawerComponent;